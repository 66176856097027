import { Component } from "react";
import "./gmailData.css";
import  automatic_order from "../../assets/images/automatic_order.png";
import  increase_cashback from "../../assets/images/increase_cashback.png";
import  enhance from "../../assets/images/enhance.png";

class GmailBody extends Component{
    render(){
        return (<div className="total-gmail-body">
            <div className="flex-center">
                <img alt="automatic order" src={automatic_order}/>
                <div className="flex-center-text-container">
                    <div>Automatic order tracking</div>
                    <div>The Gmail integration allows for a seamless tracking of orders and makes sure you never miss an order</div>
                </div>
            </div>
            <div className="flex-center">
                <img alt="increase_cashback" src={increase_cashback}/>
                <div className="flex-center-text-container">
                    <div>Increased cashback efficiency</div>
                    <div>Receive your cashback rewards more quickly and efficiently</div>
                </div>
            </div>
            <div className="flex-center">
                <img alt="enhance" src={enhance}/>
                <div className="flex-center-text-container">
                    <div>Enhanced convenience</div>
                    <div>No need to share screenshots. Orders are automatically tracked</div>
                </div>
            </div>


        </div>)
    }
}
export default GmailBody