import { Component } from "react";
import finance_submitted_img from "../../assets/images/FinanceSubmittedImg.png";
import "./financeData.css";


class FinanceSubmitted extends Component{
    render(){
        return (<div className="finance-submitted-img"><img  alt="finance img" src={finance_submitted_img}/></div>)
    }
}

export default FinanceSubmitted