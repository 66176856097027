import { Component } from "react";
import "../../Pages/gmail/gmailData.css";
import "./Faq.css"

class FAQs extends Component{

    state={
        faq_question: "",
        faq_answer:"",
        show:true,

    }

    componentDidMount(){
        let {content} =this.props?this.props:{};  
        this.setState({faq_question:content.ques,faq_answer:content.answer});
    }
    hideFaq(e){
        this.setState({show:!this.state.show});

    }
    render(){

        const {faq_question,faq_answer,show} =this.state;
        

        return (<>
        
            <div className="total-faq">
                
                <div>
                    <div className="faq-flex-dropdown">
                        <div>{faq_question}</div>
                        {show?<div className="cursor" onClick={(e)=>{this.hideFaq(e)}}>&and;</div>: <div className="cursor" onClick={(e)=>{this.hideFaq(e)}}>&or;</div>}
                       
                    </div>
                    {show&& <div className="faq-answer">{faq_answer}</div>}
                   
                </div>
               
            </div></>)

    }
}
export default FAQs