import { Component } from "react";
import "./financeData.css";
class FinanceHeading extends Component {
    render() {
        return (<div className="finance-heading">
            <div><h2>HappyCredit credit line is coming soon</h2></div>
            {/* <div className="finance-heading-last">Avail 100% paperless instant credit.</div> */}
        </div>)


    }
}

export default FinanceHeading