import { Component } from "react";
import "./gmailData.css";
import gmail_success from "../../assets/images/success.png";
import gmail_failed from "../../assets/images/close.png";
import GmailBody from "./gmailBody";
import FAQs from "../../Components/Common/Faq";

class Gmail extends Component{

    state ={
        linked:false,
        failed:false,
        gmail_linked_image:gmail_success,
        gmail_linked_status:"Gmail Linked",
        show_status:false,
        send:false

    }
    GmailLinkHandler(){
        this.setState({send:true});
    // successs
        // setTimeout(()=>{
        //     this.setState({show_status:true,send:false,linked:true, gmail_linked_status:"Gmail Linked",gmail_linked_image:gmail_success});
        // },5000)

        // fail
        setTimeout(()=>{
            this.setState({show_status:true,send:false,failed:true,gmail_linked_image:gmail_failed,gmail_linked_status:"Unable to link Gmail"});
        },5000)


        

    }

  render(){
    const {gmail_linked_image,gmail_linked_status,show_status,send,linked,failed} =this.state;
    return (<div className="total-gmail-link-part">
        <div className="gmail-link-header-img"><img alt="gmail banner" src={"https://happycredit.in/cloudinary_opt/app/web_promotions/gmail-link.png"}/></div>
        {show_status&&
         <div className="gmail-status-bar">
         <span><img alt="gmail status" src={gmail_linked_image}/></span>
         <span>{gmail_linked_status}</span>
     </div>}
        <div style={{width:"100%"}}><GmailBody/></div>
        <div className="gmail-faq">
            <div>
        <div className="Faq-title">FAQs</div>
        <div className="Faq-style">  <FAQs content ={{ques:"Which gmail ID do I need to use ?",answer:"Try to use your primary gmail ID as it will be convenient for you to check for updates."}}/></div>
        <br/>
        <div className="Faq-style"><FAQs content ={{ques:"Can I create a new gmail ID and integrate it here ?",answer:"Yes you can create a new gmail ID and use it for integration."}}/></div>
            <br/>
            <div></div>
            {!linked&&<div className="link-gmail-button-total">
                {failed? <button disabled={send} onClick={(e)=>{this.GmailLinkHandler(e)}}>{send?"...Please Wait":"Retry"}</button>:
                <button disabled={send} onClick={(e)=>{this.GmailLinkHandler(e)}}>{send?"...Please Wait":"Link Gmail"}</button>}
            </div>}
            

        </div>
        </div>  
        
    </div>)
  }
}
export default Gmail