import { Component } from "react";
import "./financeData.css";
import FinanceHeading from "./financeHeading";
import FinanceSubmitted from "./financeSubmittedForm";
import Joi from "joi-browser";
import {
   
    Input,
    FormFeedback,
    Button,
    FormGroup
   
} from "reactstrap";


class Finance extends Component {
    state = {
        data: {
            credit_product: "",
            occupation: "",
            loan_amount: "",
            cibil_score: "",
            monthly_income: "",
            pincode: "",
        },
        submitted: false,
        send: false,
        errors: {},
        product_interest:"",

    }
    schema = {
        credit_product: Joi.string().required(),
        occupation: Joi.string().required(),
        loan_amount: Joi.string().required(),
        cibil_score: Joi.string().required(),
        monthly_income: Joi.string().required(),
        pincode: Joi.string().length(6).required(),
    }
    validateForm = () => {
        const result = Joi.validate(this.state.data, this.schema, {
            abortEarly: false,
        });
        const { error } = result;
        if (!error) {
            return null;
        } else {
            const errorData = {};
            for (let item of error.details) {
                const name = item.path[0];
                if(name==="pincode"){
                    errorData[name] ="Pincode should be a 6 digit number.";
                }
                else
                {
                    errorData[name] = "Please select";
                }
              
            }
            this.setState({
                errors: errorData,
            });
            return errorData;
        }
    };
    validateProperty = (event) => {
        const { name, value } = event.target;
        const obj = { [name]: value };
        const subSchema = { [name]: this.schema[name] };
        const result = Joi.validate(obj, subSchema);
        const { error } = result;
        return error ? error.details[0].message : null;
    };
    productInterest(event){
        let {value,name} = event.target;
        let errorData = { ...this.state.errors };
        if(value===""){
            this.setState({product_interest:""});
            return;
        }
        else
        {
            value =Number(value);
            if(!value){
                delete errorData["product_interest"];
                this.setState({product_interest:0, errors: errorData });
                return
            }
            else
            {
                delete errorData["product_interest"];
                this.setState({product_interest:value,errors: errorData});
            }
            
           

        }
        
    }
    handleSubmit(event) {

        try {
            
            event.preventDefault();
            if (this.validateForm()) {
                return;
            }
            this.setState({ send: true });
            setTimeout(() => {
                this.setState({ submitted: true, send: false })
            }, 5000)

        } catch (error) {
            console.log(error)
            alert(error.message);
        }




    }
    handleChange(event) {
        let { name, value } = event.target;
        console.log(name, value)
        let errorData = { ...this.state.errors };
        const errorMessage = this.validateProperty(event);
        if (errorMessage) {
            if(name==="pincode"){
                    errorData[name] ="Pincode should be a 6 digit number.";
                }
                else
                {
                    errorData[name] = "Please select";
                }
        } else {
            delete errorData[name];
        }
        this.setState({ data: { ...this.state.data, [name]: value },errors: errorData });

    }
    ProductInterestSubmit(event){
        event.preventDefault();
       
       
        let errorData = { ...this.state.errors };
        
        let product_interest =this.state.product_interest;
        
       
        if(product_interest===""){
            errorData["product_interest"] = "Please select";
            this.setState({ errors: errorData });
            return
        }
        this.setState({send:true});

        if(product_interest==0){
            setTimeout(() => {
                this.setState({ submitted: true, send: false })
            }, 5000)
        }

        
    }

    render() {
        const { occupation, loan_amount, cibil_score, monthly_income, credit_product, pincode, send, submitted, errors,product_interest } = this.state;
        return (<div className="total-finance-part">

            <div >
                <FinanceHeading />
                
                   
                {!submitted && <>
                    <form>
                    <div className="finance-survey-box">
                        Here’s a quick survey. Please fill the below form and get notified first with best offers when HappyCredit instant credit line goes live.
                    </div>
                        <div className="finance-form-section">
                            <div>
                            <h4>Would you like to explore credit products?</h4>
                                <div>
                                <Input type="select" value={product_interest} onChange={(e) => { this.productInterest(e) }}  >
                                    <option  value="">Please Select</option>
                                    <option value={1}>Yes, I'm interested</option>
                                    <option value={0}>No, don't ask me again</option>
                                </Input>
                                <FormFeedback className="text-color-red">{errors.product_interest}</FormFeedback>
                            </div>
                            </div>
                            {product_interest?<>
                            <div>
                                <h4>Which credit product are you most interested in?</h4>
                                <Input type="select" name="credit_product"  value={credit_product}  invalid={!!errors.credit_product} onChange={(e) => { this.handleChange(e) }}  >
                                    <option value="">Please Select</option>
                                    <option value="PL">Personal Loan</option>
                                    <option value="EL">Education Loan</option>
                                    <option value="CF">EMI for shopping</option>
                                    <option value="HL">Home Loan</option>
                                    <option value="HL">Business Loan</option>
                                    <option value="others">Any other loan</option>
                                </Input>
                                <FormFeedback className="text-color-red">{errors.credit_product}</FormFeedback>
                              
                            </div>
                            <div>
                                <h4>What is loan amount range?</h4>
                                <Input type="select" name="loan_amount" value={loan_amount}  invalid={!!errors.loan_amount} onChange={(e) => { this.handleChange(e) }}  >
                                    <option value="">Please Select</option>
                                    <option value="<50k">Less than ₹50,000</option>
                                    <option value="50k-100k">₹50,000 - ₹1,00,000</option>
                                    <option value="100k-200k">₹1,00,000 - ₹2,00,000</option>
                                    <option value=">200k">More than ₹2,00,000</option>
                                </Input>
                                <FormFeedback className="text-color-red">{errors.loan_amount}</FormFeedback>
                            </div>
                            <div>
                                <h4>What is your occupation</h4>
                                <Input type="select" name="occupation" value={occupation} invalid={!!errors.occupation} onChange={(e) => { this.handleChange(e) }} >
                                    <option value="">Please Select</option>
                                    <option value="salaried">Salaried</option>
                                    <option value="self_employed">Self employed</option>
                                    <option value="others">Others</option>
                                </Input>
                                <FormFeedback className="text-color-red">{errors.occupation}</FormFeedback>
                            </div>
                            <div>
                                <h4>What is your monthly income?</h4>
                                <Input type="select" name="monthly_income"  value={monthly_income}  invalid={!!errors.cibil_score} onChange={(e) => { this.handleChange(e) }}  >
                                    <option value="">Please Select</option>
                                    <option value="<20k">Less than ₹20,000</option>
                                    <option value="20k-50k">₹20,000 - ₹50,000</option>
                                    <option value="50k-100k">₹50,000 - ₹1,00,000</option>
                                    <option value=">100k">Greater than ₹1,00,000</option>
                                </Input>
                                <FormFeedback className="text-color-red">{errors.monthly_income}</FormFeedback>
                            </div>
                            <div>
                                <h4>What is your CIBIL score?</h4>
                                <Input type="select" name="cibil_score" value={cibil_score}  invalid={!!errors.cibil_score} onChange={(e) => { this.handleChange(e) }}  >
                                    <option value="">Please Select</option>
                                    <option value="<600">less than 600</option>
                                    <option value="600-700">600 - 700</option>
                                    <option value=">700">Greater than 700</option>
                                    <option value={"dont_know"}>Don’t know my CIBIL score</option>
                                </Input>
                                <FormFeedback className="text-color-red">{errors.cibil_score}</FormFeedback>
                            </div>
                            <div>
                                <h4>Please enter your area pincode</h4>
                                <Input type="number"  name="pincode" placeholder="Ex:517592"  value={pincode}  invalid={!!errors.pincode} onChange={(e) => { this.handleChange(e) }}  >
                                </Input>
                                <FormFeedback className="text-color-red">{errors.pincode}</FormFeedback>
                            </div>
                            </>:""}
                            
                            <div className="finance-submit-form">
                                <button disabled={send} onClick={(e) => {!product_interest?this.ProductInterestSubmit(e):this.handleSubmit(e) }} >{send ? "...Please Wait" : "Submit form"}</button>
                            </div>

                        </div>
                    </form>
                </> }
                  
                  {submitted&&<FinanceSubmitted/>}
                
                
               
            </div>
        </div>)
    }
}

export default Finance
