import { Component } from "react";
import {createBrowserRouter,RouterProvider} from "react-router-dom";
import Gmail from "./Pages/gmail/totalGmail";
import Finance from "./Pages/finance/totalFinance";
import NotFound from "./Components/Common/NotFound.js/notFound";


const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <div>
          <NotFound/>
        </div>
      ),
    },
    {
      path:"gmail-link",
      element:(<Gmail/>)
    }
    ,{
      path:"finance",
      element:<Finance/>
    }
  ]);
class Router extends Component{
    render(){
        return <RouterProvider router={router} />
    }
}
export default Router
  