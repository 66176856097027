import './App.css';
import Router from './route';
import { Component } from 'react';


class App extends Component{
 
  render(){
    return (<Router/>)
  }
}

export default App;

