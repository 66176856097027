import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

class NotFound extends Component {
  render() {
    return (
      <React.Fragment>
        <div style={{width:"90%",margin:"100px auto"}} className="">
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="row pt-5">
                    <div className="col-lg-5 offset-lg-1">
                      <div>
                        <h4 className="faq-question" data-wow-delay=".1s">
                          Not Found
                        </h4>
                        <p  style={{marginLeft:"50px",color:"#98a6ad",fontSize:"17px",lineHeight:"20px"}}>
                          The page you were looking for could not be found. It
                          might have been removed, renamed or did not exist in
                          the first place{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default NotFound;
